<script setup lang="ts">
import {
    AntlerClasses,
    installAntlerComponent,
    responsive,
} from "@/Utils/component";

export type ArticleAs = "article" | "div";
export type ArticleSize = "small" | "regular" | "large";
export type ArticleColor = "dark" | "light" | "white";
export type ArticleWeight = "normal" | "bold";
export type ArticleVariant = "noMaxWidth" | "excerpt";

type Props = {
    as?: ArticleAs;
    size?: ArticleSize;
    color?: ArticleColor;
    weight?: ArticleWeight;
    variant?: ArticleVariant | ArticleVariant[];
};

const {
    as = "article",
    size = "regular",
    color = "dark",
    weight = "normal",
    variant = "",
} = defineProps<Props>();

// classes
const classes: AntlerClasses<Props> = {
    base: "prose selection:bg-theme/10 selection:text-theme prose-a:text-theme whitespace-pre-line hyphens-auto font-normal break-words",
    variants: {
        size: {
            small: "prose-sm",
            regular: "prose-base",
            large: responsive("prose-lg"),
        },
        color: {
            dark: "",
            light: "",
            white: "text-white",
        },
        weight: {
            normal: "font-normal",
            bold: "font-bold",
        },
        variant: {
            noMaxWidth: "max-w-none",
            excerpt: "whitespace-normal",
        },
    },
};
const { aClass } = installAntlerComponent(
    "article",
    { size, color, variant },
    classes,
);
</script>

<template>
    <component
        :is="as"
        :class="aClass()"
        :role="as !== 'article' ? 'article' : ''"
    >
        <slot />
    </component>
</template>
